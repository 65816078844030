/* SASS Variables for use throughout project
 * See TaskTrain Coding Conventions > Components > Styling > Naming >SASS Variable for naming convention
 * https://docs.google.com/document/d/1P7Tl_qxri-aVMJP5h6zWN3TG9cLwjAWPnABaT9nUKJU/edit#heading=h.j7w7j94cuvfh
 */
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

/* Fonts */
$tt-font-family: 'Roboto', 'Trebuchet MS', 'Arial', 'Helvetica', sans-serif;
$tt-font-size: 0.875rem;

/* Border/Shadow */
$tt-border-radius: 0.33rem;
$tt-chips-border-radius: 0.75rem;
$tt-button-box-shadow: 0.05rem 0.05rem 0.175rem 0 rgb(0 0 0 / 16%);

/* Padding/Margin/Height */
$tt-header-padding: 0.625rem;
$tt-content-padding: 0.875rem;
$tt-content-margin: 0.875rem;
$tt-row-height: 2.75rem;
$tt-table-row-padding: 0.5rem 0.625rem;

/* TaskTrain brand colors & fonts */
$tt-brand-orange: #f26322;
$tt-brand-blue: #284a6c;
$tt-brand-font: 'Avenir Next condensed';

/* TaskTrain color palette */
$tt-color-white: white;
$tt-color-black: black;

/* Theme hues - green (PrimeNG Nova) */
$tt-color-green-050: #f7fbf6;
$tt-color-green-100: #d6ead1;
$tt-color-green-200: #b6d9ad;
$tt-color-green-300: #96c889;
$tt-color-green-400: #76b765;
$tt-color-green-500: #56a641;
$tt-color-green-600: #498d37;
$tt-color-green-700: #3c742e;
$tt-color-green-800: #2f5b24;
$tt-color-green-900: #22421a;

/* Theme hues - blue (PrimeNG Nova) */
$tt-color-blue-050: #f3f8fc;
$tt-color-blue-100: #c6dcef;
$tt-color-blue-200: #98c1e3;
$tt-color-blue-300: #6ba5d7;
$tt-color-blue-400: #3d8aca;
$tt-color-blue-500: #106ebe;
$tt-color-blue-600: #0e5ea2;
$tt-color-blue-700: #0b4d85;
$tt-color-blue-800: #093d69;
$tt-color-blue-900: #062c4c;

/* Theme hues - red  (+/- 6 tints/shades on #DC3522 via https://colordesigner.io/ */
$tt-color-red-050: #f9ddda;
$tt-color-red-100: #f4bbb5;
$tt-color-red-200: #ef9a9a;
$tt-color-red-300: #e8786b;
$tt-color-red-400: #e25646;
$tt-color-red-500: #b72c1c;
$tt-color-red-600: #932317;
$tt-color-red-700: #6e1a11;
$tt-color-red-800: #49120b;
$tt-color-red-900: #250906;

/* Theme hues - cyan (PrimeNG Nova) */
$tt-color-cyan-050: #f4fbfd;
$tt-color-cyan-100: #ccebf5;
$tt-color-cyan-200: #a4dbed;
$tt-color-cyan-300: #7ccce5;
$tt-color-cyan-400: #54bcdd;
$tt-color-cyan-500: #2cacd5;
$tt-color-cyan-600: #2592b5;
$tt-color-cyan-700: #1f7895;
$tt-color-cyan-800: #185f75;
$tt-color-cyan-900: #124555;

/* Theme hues - pink (PrimeNG Nova) */
$tt-color-pink-050: #fdf4f9;
$tt-color-pink-100: #f6cce0;
$tt-color-pink-200: #eea3c7;
$tt-color-pink-300: #e77aae;
$tt-color-pink-400: #df5296;
$tt-color-pink-500: #d8297d;
$tt-color-pink-600: #b8236a;
$tt-color-pink-700: #971d58;
$tt-color-pink-800: #771745;
$tt-color-pink-900: #561032;

/* Theme hues - gray (PrimeNG Bootstrap) */
$tt-color-gray-050: #f9fafb;
$tt-color-gray-100: #f8f9fa;
$tt-color-gray-200: #e9ecef;
$tt-color-gray-300: #dee2e6;
$tt-color-gray-400: #ced4da;
$tt-color-gray-500: #adb5bd;
$tt-color-gray-600: #6c757d;
$tt-color-gray-700: #495057;
$tt-color-gray-800: #343a40;
$tt-color-gray-900: #212529;

/* Theme hues - orange (PrimeNG Nova) */
$tt-color-orange-50: #fff9f5;
$tt-color-orange-100: #fde4cd;
$tt-color-orange-200: #fbcfa6;
$tt-color-orange-300: #f9ba7f;
$tt-color-orange-400: #f8a457;
$tt-color-orange-500: #f68f30;
$tt-color-orange-600: #d17a29;
$tt-color-orange-700: #ac6422;
$tt-color-orange-800: #874f1a;
$tt-color-orange-900: #623913;

/* Theme hues - yellow (PrimeNG Nova) */
$tt-color-yellow-050: #fffcf5;
$tt-color-yellow-100: #fdefcd;
$tt-color-yellow-200: #fbe3a6;
$tt-color-yellow-300: #f9d67f;
$tt-color-yellow-400: #f8ca57;
$tt-color-yellow-500: #f6bd30;
$tt-color-yellow-600: #d1a129;
$tt-color-yellow-700: #ac8422;
$tt-color-yellow-800: #87681a;
$tt-color-yellow-900: #624c13;

/* Theme hues - indigo (PrimeNG Rhea) */
$tt-color-indigo-050: #f7f9fe;
$tt-color-indigo-100: #d6e4f8;
$tt-color-indigo-200: #b6cef2;
$tt-color-indigo-300: #96b8ec;
$tt-color-indigo-400: #75a3e7;
$tt-color-indigo-500: #558de1;
$tt-color-indigo-600: #4878bf;
$tt-color-indigo-700: #3c639e;
$tt-color-indigo-800: #2f4e7c;
$tt-color-indigo-900: #22385a;

/* Theme hues - teal (PrimeNG Nova) */
$tt-color-teal-050: #f6fbfa;
$tt-color-teal-100: #d1eae5;
$tt-color-teal-200: #add9d1;
$tt-color-teal-300: #89c8bd;
$tt-color-teal-400: #65b7a8;
$tt-color-teal-500: #41a694;
$tt-color-teal-600: #378d7e;
$tt-color-teal-700: #2e7468;
$tt-color-teal-800: #245b51;
$tt-color-teal-900: #1a423b;

/* Theme hues - bluegray (PrimeNG Nova) */
$tt-color-bluegray-050: #f7f8f9;
$tt-color-bluegray-100: #dae0e3;
$tt-color-bluegray-200: #bdc7cd;
$tt-color-bluegray-300: #a0aeb6;
$tt-color-bluegray-400: #8295a0;
$tt-color-bluegray-500: #657c8a;
$tt-color-bluegray-600: #566975;
$tt-color-bluegray-700: #475761;
$tt-color-bluegray-800: #38444c;
$tt-color-bluegray-900: #283237;

/* Theme hues - purple (PrimeNG Nova) */
$tt-color-purple-050: #f9f8fd;
$tt-color-purple-100: #e1dff7;
$tt-color-purple-200: #cac5f1;
$tt-color-purple-300: #b2abeb;
$tt-color-purple-400: #9b92e4;
$tt-color-purple-500: #8378de;
$tt-color-purple-600: #6f66bd;
$tt-color-purple-700: #5c549b;
$tt-color-purple-800: #48427a;
$tt-color-purple-900: #343059;

/* Generic state / action */
$tt-color-success: $tt-color-green-300;
$tt-color-danger: $tt-color-red-400;
$tt-color-warning: $tt-color-orange-400;
$tt-color-info: $tt-color-blue-400;
$tt-color-primary: $tt-color-success;
$tt-color-secondary: $tt-color-gray-600;
$tt-color-help: $tt-color-info;

/* Generic CSS State */
$tt-color-active: $tt-color-indigo-100;
$tt-color-hover: $tt-color-gray-300;
$tt-color-default: $tt-color-gray-200;
$tt-opacity-disabled: 66%;

/* Generic Text */
$tt-text-color: $tt-color-black;
$tt-text-color-muted: $text-muted;

/* Active ("Selected") State */
$tt-border-color-active: $tt-color-indigo-200;
$tt-background-color-active: $tt-color-active;
$tt-text-color-active: $tt-color-blue-900;

/* Hover ("Highlight") State */
$tt-border-color-hover: $tt-color-gray-500;
$tt-background-color-hover: $tt-color-gray-300;
$tt-text-color-hover: $tt-color-black;

/* Focus ("Input") State */
$tt-border-color-focus: $tt-color-gray-500;
$tt-border-color-focus: $tt-color-indigo-700;
$tt-box-shadow-color-focus: $tt-color-gray-200;
$tt-background-color-focus: $tt-color-gray-200;
$tt-text-color-focus: $tt-color-black;

/* Error ("Invalid") State */
$tt-border-color-error: $tt-color-danger;
$tt-background-color-error: #f5554a;
$tt-text-color-error: #cd0a0a;

/* Generic location */
$tt-border-color: $tt-color-gray-400;
$tt-header-background-color: $tt-color-default !default;
$tt-header-background-color-hover: $tt-color-hover;
$tt-header-border-color: $tt-border-color;
$tt-header-text-color: $tt-color-black;
$tt-header-text-color-hover: $tt-text-color-active;
$tt-header-icon-color: $tt-text-color;
$tt-content-background-color: $tt-color-white;
$tt-content-background-color-hover: $tt-color-hover;
$tt-content-border-color: $tt-border-color;
$tt-content-text-color: $tt-text-color;
$tt-empty-state-text-color: $tt-color-gray-800;
$tt-description-color-active: $tt-color-gray-100;

/* AssignmentTaskStatus */
$tt-assignment-task-status-not-started-color: $tt-color-gray-800;
$tt-assignment-task-status-in-progress-color: $tt-color-green-400;
$tt-assignment-task-status-on-hold-color: $tt-color-orange-300;
$tt-assignment-task-status-skipped-color: $tt-color-gray-600;
$tt-assignment-task-status-completed-color: $tt-color-blue-400;
$tt-assignment-task-status-category-open-color: $tt-color-yellow-600;
$tt-assignment-task-status-category-closed-color: $tt-color-blue-600;

/* Bootstrap4 overrides */
$grid-gutter-width: $tt-content-padding;
$h1-font-size: $font-size-base;
$h2-font-size: $font-size-base;
$h3-font-size: $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

/* Forms */
$tt-input-background-color: $tt-color-white;
$tt-input-background-color-disabled: $tt-color-gray-200;
$tt-input-group-prepend-background-color: $tt-color-gray-200 !default;
$tt-input-text-color: $tt-color-black;
$tt-input-text-border-color-focus: $tt-color-indigo-700;
$tt-input-text-border-color-hover: $tt-color-indigo-300;
$tt-input-border-color-error: $tt-color-danger;
$tt-input-group-text-color: $tt-color-black;

/* Checkbox */
$tt-checkbox-background-color: $tt-color-white;
$tt-checkbox-color: $tt-color-gray-900;

/* Tab */
$tt-tabview-background-color: $tt-color-gray-100;
$tt-tabview-background-color-hover: $tt-color-gray-200;
$tt-tabview-border-color: $tt-border-color;
$tt-tabview-background-color-selected: $tt-color-white;
$tt-tabview-border-bottom-color-selected: $tt-color-white;
$tt-tabview-text-color-selected: $tt-text-color-active;

/* Button */
$tt-button-icon-only-size: 1.75rem;
$tt-button-create-background-color: $tt-color-green-400;
$tt-button-create-color-hover: $tt-color-green-500;
$tt-button-create-border-color: $tt-color-green-500;
$tt-button-create-color: $tt-color-white;
$tt-button-filter-background-color: $tt-color-blue-400;
$tt-button-filter-color: $tt-color-white;
$tt-button-filter-border-color: $tt-color-blue-500;
$tt-button-update-color: $tt-color-white;
$tt-button-update-background-color: $tt-color-yellow-400;
$tt-button-update-border-color: $tt-color-yellow-500;
$tt-button-update-color-hover: $tt-color-yellow-500;
$tt-button-account-update-color: $tt-text-color-active;
$tt-button-account-update-background-color: $tt-color-white;
$tt-button-account-update-color-hover: $tt-color-hover;
$tt-button-delete-color: $tt-color-white;
$tt-button-delete-background-color: $tt-color-red-300;
$tt-button-delete-border-color: $tt-color-red-400;
$tt-button-delete-color-hover: $tt-color-red-400;
$tt-button-cancel-color: $tt-color-white;
$tt-button-cancel-background-color: $tt-color-gray-500;
$tt-button-cancel-border-color: $tt-color-gray-600;
$tt-button-cancel-color-hover: $tt-color-gray-600;
$tt-button-info-color: $tt-color-white;
$tt-button-info-background-color: $tt-color-blue-300;
$tt-button-info-border-color: $tt-color-blue-400;
$tt-button-info-background-color-hover: $tt-color-blue-400;
$tt-button-icon-background-color: $tt-color-white;
$tt-button-icon-color: $tt-color-gray-800;
$tt-toggle-button-color-active: $tt-color-blue-900;
$tt-toggle-button-background-color: $tt-color-white;
$tt-toolbar-button-color-disabled: $tt-color-gray-500;

/* Icon */
$tt-icon-search-color: $tt-color-gray-500;
$tt-icon-edit-color: $tt-color-blue-500;
$tt-icon-download-color: $tt-color-gray-700;
$tt-icon-external-link-color: $tt-color-gray-700;
$tt-icon-expand-color: $tt-color-gray-700;
$tt-icon-remove-color: $tt-color-gray-700;
$tt-icon-remove-color-hover: $tt-color-gray-900;
$tt-icon-check-circle-color: $tt-color-green-400;
$tt-icon-check-circle-color-active: $tt-color-white;
$tt-icon-background-color: $tt-color-gray-200 !default;

/* File upload */
$tt-fileupload-content-background-color: $tt-color-gray-200;
$tt-fileupload-files-background-color: $tt-color-white;
$tt-fileupload-files-background-color-active: $tt-color-active;
$tt-fileupload-choose-content-icons-color: $tt-color-blue-200;

/* Treeview */
$tt-tree-row-background-color-active: $tt-color-active;
$tt-tree-row-background-color-hover: $tt-color-hover;
$tt-tree-row-background-color-focus: $tt-color-hover;
$tt-tree-row-border-color: $tt-color-orange-500;
$tt-tree-expander-color: $tt-color-blue-800;
$tt-node-drop-slot-background-color: $tt-color-white;
$tt-node-drop-slot-drag-background-color: $tt-color-orange-100;
$tt-node-drop-slot-drag-border-color: $tt-color-orange-400;

/* Menu */
$tt-child-menu-background-color: $tt-header-background-color;
$tt-nav-menu-user-text-color: $tt-color-black;
$tt-nav-menu-user-text-color-active: $tt-color-black;
$tt-menu-item-background-color-active: $tt-color-active;
$tt-menu-item-text-color: $tt-color-black;
$tt-menu-item-text-color-active: $tt-color-blue-900;
$tt-menu-disclosure-icon-color: $tt-color-gray-800;
$tt-menu-disclosure-icon-color-hover: $tt-text-color-active;
$tt-list-item-background-color-active: $tt-color-active;
$tt-list-item-background-color-hover: $tt-color-hover;
$tt-menu-overlay-background: $tt-color-white;
$tt-panelmenu-header-border-color: $tt-header-border-color;
$tt-menubar-background-color: $tt-color-white;
$tt-autodropdown-menu-background-color: $tt-color-white;
$tt-autodropdown-menu-border-color: $tt-border-color;
$tt-autodropdown-menu-background-color-active: $tt-color-active;
$tt-autocomplete-token-icon-color: $tt-color-gray-700;

/* Badge */
$tt-badge-color-nonzero: $tt-color-orange-200;
$tt-badge-color-zero: $tt-color-gray-500;
$tt-badge-button-color: $tt-color-white;
$tt-badge-icon-background-color: $tt-color-blue-200;
$tt-badge-icon-color: $tt-color-white;
$tt-badge-search-box-color: $tt-color-orange-500;

/* Chips */
$tt-chip-background-color: $tt-color-orange-100;
$tt-categorization-keyword-background-color: $tt-color-purple-200;
$tt-categorization-function-background-color: $tt-color-teal-200;
$tt-categorization-sector-background-color: $tt-color-cyan-200;

/* Tooltip */
$tt-tooltip-background-color: $tt-color-blue-050;
